<template>
    <div>
        <Header :id="id" :exhibitionId="exhibitionId"></Header>
        <div class="spbanner">
            <img :src="conmpany.indexThumb" alt="">
        </div>
        <div class="main">
            <div class="info">
                <div class="ileft">
                    <div class="ileft1">{{ $t('Category') }}</div>
                    <div class="ileft2">
                        <div :class="categoryId ? 'ileft3' : 'ileft3 active'" @click="changeAll()">{{ $t('ALL') }}</div>
                        <div :class="categoryId==item.categoryId? 'ileft3 active': 'ileft3'" v-for="(item,index) in cateList" :key="index" @click="changeProduct(item.categoryId)">
                            {{item.categoryName}}
                        </div>
                    </div>
                </div>
                <div class="iright">
                    <div class="contact">
                        <div class="tag"></div>
                        <div class="us">{{ $t('products') }}</div>
                    </div>
                    <div class="iright1">
                        <div class="pro1">
                            <div class="duct1" v-for="(item,index) in products.lists" :key="index">
                                <div class="proimg">
                                    <router-link :to="{ name: 'Productdetail', params: {  id: id, productId: item.productId } }">
                                    <img :src="item.thumb" alt="">
                                    </router-link>
                                </div>
                                <div class="protxt">
                                    {{item.productName}}
                                </div>
                                <div class="protxt2">
                                      <span>{{item.minPrice}}</span>
                                </div>
                                <div class="protxt3">{{ $t('Min.Order') }}: {{item.minOrder}}</div>
                                <div class="rtxt6">
                                  <div class="rtxt5">{{ $t('contact.now') }}</div>
                                  <div class="rheart red" v-if="item.isLike == 1" @click="unlike(item.productId)"><a-icon type="heart" /></div>
                                  <div class="rheart" v-else @click="like(item.productId)"><a-icon type="heart" /></div>
                                </div>
                            </div>    
                        </div>
                    </div>
                    <!-- 分页 -->
                    <div class="page">
                        <a-pagination
                            showQuickJumper
                            :defaultCurrent="pagination.defaultCurrent"
                            :defaultPageSize="pagination.defaultPageSize"
                            :total="pagination.total"
                            @change="onChange"
                            @showSizeChange="onShowSizeChange"
                        />
                    </div>
                </div>
            </div>
            <Contact></Contact>
        </div>
        <Footer></Footer>
    </div>
</template>

<script>
import Header from "./components/Header";
import Footer from "../components/Footer";
import Contact from "./components/Contact";

export default {
  name: "SupplierProduct",
  inject: ['reload'], // 注入依赖
  components: {
    Footer,
    Header,
    Contact
  },
  data() {
    return {
      page: 1,
      exhibitionId: this.$route.params.exhibitionId,
      id: this.$route.params.id,
      conmpany: [],
      cateList: [],
      categoryId: this.$route.params.categoryId || '',
      products: [],
      pagination: {
        showQuickJumper: true,
        showSizeChanger: true,
        defaultCurrent: 1, // 默认当前页数
        defaultPageSize: 8, // 默认当前页显示数据的大小
        total: 0 // 总数，必须先有
      },
    };
  },
  mounted () {
    console.log('cate', this.categoryId)
    this.getList()
    this.getProducts()
    this.getConmpany()
  },
  // 路由更新
  beforeRouteUpdate (to, from, next) {
    this.categoryId = to.params.categoryId || ''
    this.getProducts()
  },
  methods: {
    // 获取公司详情
    getConmpany () {
      this.$common.fetchList('/company/detail/'+this.id, {}).then(data => {
        if (data.code === 200) {
          const result = data.data
          this.conmpany = result
        }
      }).catch(() => {
        // this.$notification.error({
        // message: '错误',
        // description: '请求信息失败，请重试'
        // })
      })
    },
    // 获取行业分类
    getList () {
       this.$common.fetchList('/exhibition/category/lists?parentId=0', {}).then(data => {
        if (data.code === 200) {
          const result = data.data
          this.cateList = result
        }
      }).catch(() => {
        // this.$notification.error({
        // message: '错误',
        // description: '请求信息失败，请重试'
        // })
      })
    },
    //点击选择商品
    changeProduct(categoryId) {
        this.categoryId = categoryId
        this.getProducts()
    },
    //选择全部商品
    changeAll () {
        this.categoryId = ''
        this.getProducts()
    },
    //分页
    onShowSizeChange (current, pageSize) {
      this.pagination.defaultCurrent = 1
      this.pagination.defaultPageSize = pageSize
      this.getProducts() // 显示列表的接口名称
    },
    onChange(current, size) {
      this.pagination.defaultCurrent = current
      this.pagination.defaultPageSize = size
      // 查数据
      this.getProducts()
    },
    // 展商展品页面
    getProducts () {
        const params = {
            page: this.pagination.defaultCurrent,
            pagesize: this.pagination.defaultPageSize
       }
       this.$common.fetchList('/company/product/lists?companyId='+this.id+'&categoryId='+this.categoryId, Object.assign({}, params)).then(data => {
        if (data.code === 200) {
          const result = data.data
          const pagination = { ...this.pagination }
          pagination.total = result.totalInfo.total_items
          this.products = result
          this.pagination = pagination
        }
      }).catch(() => {
        // this.$notification.error({
        // message: '错误',
        // description: '请求信息失败，请重试'
        // })
      })
    },
     //收藏
    like(productId) {
      this.$common.handlePost('/index/product/like', {productId:productId}).then(data => {
      if (data.code === 200) {
          this.$layer.alert(data.msg);
          this.reload()
      }
      }).catch(() => {
        // this.$notification.error({
        // message: '错误',
        // description: '请求信息失败，请重试'
        // })
      })
    },
    unlike (productId) {
      this.$common.handlePost('/index/product/unlike', {productId:productId}).then(data => {
      if (data.code === 200) {
          this.$layer.alert(data.msg);
          this.reload()
      }
      }).catch(() => {
        // this.$notification.error({
        // message: '错误',
        // description: '请求信息失败，请重试'
        // })
      })
    }
  }
}
</script>

<style lang="less" scoped>
  @import '~@/assets/css/spproduct.less';
</style>